<template>
  <div class="d-none d-lg-block mt-4">
    <b-container>
      <b-card border-variant="light" class="side-bar shadow-sm rounded">
        <div class="sidebar-heading d-flex justify-content-center mb-2">
          {{ $t("menu") }}
        </div>
        <b-list-group flush>
          <b-list-group-item
            data-cy="sidebar-item"
            v-for="item in items"
            :key="item.text"
            :class="$route.path === item.to ? 'active-link' : ''"
            class="list-group-item list-group-item-action mouse-style"
            @click="go(item.to)"
            @click.middle="openInANewTab(item.to)"
          >
            <b-icon :icon="item.icon" class="mr-2" />
            {{ item.text }}
          </b-list-group-item>
        </b-list-group>
      </b-card>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "SideBar",
  props: { items: Array },
  methods: {
    go(to) {
      this.$router.push(to).catch((err) => {
        if (err.name === "NavigationDuplicated") {
          this.$emit("refresh");
        }
      });
    },
    openInANewTab(itm) {
      let route = this.$router.resolve({
        path: itm,
      });
      window.open(route.href);
    },
  },
};
</script>

<style scoped>
.side-bar {
  min-width: 250px;
}

.sidebar-heading {
  color: #495057;
  font-size: 1.3rem;
  font-weight: 600;
}
.mouse-style {
  cursor: pointer;
}
.active-link {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
</style>
