<template>
  <b-modal id="modal-1" :title="$t('changeRole')">
    <b-form-select
      v-model="selected"
      :options="options"
      data-cy="change-role-form-select"
    />

    <template #modal-footer="{ ok, cancel }">
      <b-button
        class="custom-danger-button"
        pill
        variant="danger"
        @click="cancel()"
      >
        {{ $t("changeRoleModalCancel") }}
      </b-button>
      <b-button
        data-cy="change-role-modal-ok"
        pill
        variant="primary"
        @click="handleOk(ok)"
      >
        {{ $t("changeRoleModalOk") }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { getRefreshToken } from "axios-jwt";

export default {
  name: "ChangeRole",
  data() {
    return {
      selected: this.$store.getters.activeRole,
      options: [],
    };
  },
  mounted() {
    const roles = this.$store.getters.roles;
    const rolesDisplay = this.$store.getters.rolesDisplay;

    this.options = roles.map((role) => {
      const foundRoleDisplay = rolesDisplay.find(
        (display) => display.toLowerCase() === role
      );
      return { value: role, text: foundRoleDisplay };
    });
  },
  methods: {
    async handleOk(ok) {
      try {
        const { data } = await this.$http.put(
          this.$cfg.BASE_IDENTITY_URL + "/v0/refresh-login",
          {
            activeRole: this.selected,
            refreshToken: getRefreshToken(),
          }
        );
        await this.$store.dispatch("changeRole", data);
        this.$router.push("/qres").catch(() => {});
      } catch (err) {
        if (err.response) console.log(err.response);
      } finally {
        ok();
      }
    },
  },
};
</script>
