<template>
  <div class="d-flex flex-column full">
    <Nav v-bind:username="username" />
    <div class="d-flex bg-cloud flex-grow-1">
      <SideBar :items="sideBarItems" v-on:refresh="componentRefresh += 1" />
      <div class="container mb-5">
        <router-view :key="componentRefresh" />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Nav from "@/components/NavBar.vue";
import SideBar from "@/components/SideBar.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  components: {
    Footer,
    Nav,
    SideBar,
  },
  data() {
    return {
      componentRefresh: 0,
    };
  },
  computed: {
    username: function () {
      return this.$store.getters.username + "";
    },
    sideBarItems: function () {
      return [
        { text: "QREs", to: "/qres", icon: "server" },
        {
          text: this.$i18n.t("orgs.title"),
          to: "/organizations",
          icon: "people-fill",
        },
      ];
    },
  },
};
</script>
