<template>
  <b-navbar
    :variant="watermark === 'true' ? 'primary' : 'dark'"
    toggleable="lg"
    type="dark"
  >
    <b-navbar-brand class="mr-2" to="/qres">
      <img :src="logo" alt="Qognite Logo" class="logo" />
    </b-navbar-brand>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="d-none d-lg-flex">
        <b-nav-item
          :active="$route.matched[0].path === ''"
          data-cy="home-menu"
          to="/qres"
        >
          {{ $t("navBar.home") }}
        </b-nav-item>

        <!-- only visible if admin -->
        <b-nav-item
          v-if="this.$store.getters.isAdmin"
          :active="$route.matched[0].path === '/admin'"
          data-cy="admin-menu"
          to="/admin"
        >
          {{ $t("navBar.admin") }}
        </b-nav-item>
      </b-navbar-nav>
      <!-- only visible on small screens -->
      <b-navbar-nav class="d-flex d-lg-none">
        <b-nav-item-dropdown
          :class="$route.matched[0].path === '' ? 'show' : ''"
          :menu-class="$route.matched[0].path === '' ? 'show' : ''"
          right
          text="Home"
        >
          <b-dropdown-item
            v-for="item in homeRoutes"
            :key="item.text"
            :active="$route.path === item.to"
            :to="item.to"
            active-class="active-nav-item"
            exact
          >
            {{ item.text }}
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>

      <b-navbar-nav v-if="this.$store.getters.isAdmin" class="d-flex d-lg-none">
        <b-nav-item-dropdown
          :class="$route.matched[0].path === '/admin' ? 'show' : ''"
          :menu-class="$route.matched[0].path === '/admin' ? 'show' : ''"
          right
          text="Admin"
        >
          <b-dropdown-item
            v-for="item in adminRoutes"
            :key="item.text"
            :active="$route.path === item.to"
            :to="item.to"
            active-class="active-nav-item"
            exact
          >
            {{ item.text }}
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>

      <b-navbar-nav v-if="isAdmin" class="d-flex d-lg-none">
        <b-nav-item-dropdown
          :class="$route.matched[0].path === '/self' ? 'show' : ''"
          :menu-class="$route.matched[0].path === '/self' ? 'show' : ''"
          :text="username"
          right
        >
          <b-dropdown-item
            v-for="item in profileRoutes"
            :key="item.text"
            :active="$route.path === item.to"
            :to="item.to"
            active-class="active-nav-item"
            exact
          >
            {{ item.text }}
          </b-dropdown-item>
          <b-dropdown-item v-b-modal.modal-1>
            {{ $t("navBar.changeRole") }}
          </b-dropdown-item>
          <b-dropdown-item @click="logout">
            {{ $t("navBar.signOut") }}
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto d-none d-lg-flex">
        <b-nav-item-dropdown data-cy="user-menu" right>
          <!-- Using 'button-content' slot -->
          <template #button-content>{{ username }}</template>
          <b-dropdown-item to="/self/account">
            {{ $t("navBar.settings") }}
          </b-dropdown-item>
          <b-dropdown-item
            v-b-modal.modal-1
            :disabled="this.userRoles.length < 1"
            data-cy="change-role"
          >
            {{ $t("navBar.changeRole") }}
          </b-dropdown-item>
          <b-dropdown-item data-cy="sign-out" @click="logout">
            {{ $t("navBar.signOut") }}
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>

    <ChangeRole />
  </b-navbar>
</template>

<script>
import ChangeRole from "@/components/Modals/ChangeRoleModal.vue";
import qognite_logo from "@/assets/images/qognite-logo.svg";

export default {
  name: "Nav",
  props: {
    brand: String,
    username: String,
  },
  components: { ChangeRole },
  methods: {
    logout() {
      this.$router.push("/login");
      this.$store.dispatch("logout");
    },
  },
  data() {
    return {
      logo: qognite_logo,
      userRoles: this.$store.getters.roles,
      watermark: process.env.VUE_APP_WATERMARK_DEVELOPMENT,
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    adminRoutes() {
      return [
        { text: "Dashboard", to: "/admin", icon: "bar-chart-fill" },
        { text: "Users", to: "/admin/users", icon: "people-fill" },
        { text: "Organizations", to: "/admin/organizations", icon: "building" },
        { text: "Nodes", to: "/admin/nodes", icon: "cpu-fill" },
        { text: "QREs", to: "/admin/qres", icon: "server" },
      ];
    },
    homeRoutes() {
      return [
        { text: "QREs", to: "/qres", icon: "server" },
        // { text: "Organizations", to: "/organizations", icon: "people-fill" },
        // { text: "Access Tokens", to: "/tokens", icon: "key-fill" },
        // { text: "Resources", to: "/resources", icon: "book" },
      ];
    },
    profileRoutes() {
      return [
        // { text: "Account", to: "/self/account" },
        { text: "Password", to: "/self/password" },
        { text: "Language", to: "/self/language" },
      ];
    },
  },
};
</script>

<style scoped>
.logo {
  max-height: 30px;
  filter: invert(100%) sepia(0%) saturate(7475%) hue-rotate(48deg)
    brightness(108%) contrast(107%);
}
.navbar.navbar-dark.bg-dark {
  background-color: #000000 !important;
}
</style>
